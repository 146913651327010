import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import validator from "validator";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { Browser, getUserInfo } from "../../utils/locationInfo";
import "./index.scss";
import ToggleInput from "../../componentParts/ToggleInput";
import { customBaseUrl, httpPost, httpGet } from "../../services/http";
import Button from "../../componentParts/Button";
import ForgotPassword from "../../componentParts/Modals/ForgotPassword";
import ResetPassword from "../../componentParts/Modals/ResetPassword";
import ResendOtp from "../../componentParts/Modals/Otp/ResendOtpOutside";
import VerifyOtp from "../../componentParts/Modals/Otp/VerifyOtp";
import Logo from "../../assets/newlog.svg";
import quickLogo from "../../assets/wayaquick.png";
import posLogo from "../../assets/wayaPos.png";
import gramLogo from "../../assets/wayagram.png";
import { envConfig } from "../../utils/envConfig";
import { checkAcess, encrypt } from "../../utils/helper";
import { isExpired, decodeToken } from "react-jwt";
import ResetPasswordModal from "../../componentParts/Modals/resetNotify";
import { hideLoader, showLoader } from "../../utils/loader";
import SelectAccount from "../../componentParts/Modals/SelectAccount";
import { Skeleton } from "antd";
import { Modal } from "reactstrap";
const LoginPage = (props) => {
  const history = useHistory();
  const [location, setLocation] = useState({});
  const [data, setData] = useState({
    emailOrPhoneNumber: "",
    password: "",
  });
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [confirmOtp, setConfirmOtp] = useState("");

  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showVerifyOtp, setShowVerifyOtp] = useState(false);
  const [showResendOtp, setShowResendOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sharedPost, setSharedPost] = useState(false);
  const [sharedPostId, setSharedPostId] = useState(null);
  const [otp, setOtp] = useState("");
  const [passwordAge, setPasswordAge] = useState("");
  const [showPasswordReset, setShowPasswordreset] = useState(false);
  const [checkReset, setCheckReset] = useState(false)
  const [showVerifyAccount, setShowVerifyAccount] = useState(false);
  const [showAccountOption, setShowAccountOption] = useState(false);
  const [userData, setUserData] = useState();
  const [isPersonal, setIsPersonal] = useState(true);
  const [pWarning, setPwarnin] = useState('');
  const [client, setClient] = useState(null);
  const [bId, setBId] = useState('');
  const [resetBID, setResetBID] = useState('');
  const [resetEmail, setResetEmail] = useState('');
  const [userId, setUserId] = useState(null);
  const [showValidateBVN, setShowValidateBVN] = useState(false);
  const getLocationInfo = async () => {
    const userBrowserName = Browser.getBrowserName();
    const userInfo = await getUserInfo();
    setLocation({
      device: userBrowserName,
      userLocation: userInfo,
    });
  };

  useEffect(() => {
    const {
      location: { search },
    } = props;
    const clientId= new URLSearchParams(search).get("client");
    setClient(clientId ?? 'wayabank');
    if (clientId === 'wayapos' || clientId === 'wayaquick') {
      localStorage.setItem('clientType', 'CORPORATE');
      localStorage.setItem('clientId', clientId ? clientId.toUpperCase() : 'WAYABANK');
      setIsPersonal(false);
    } else {
      localStorage.setItem('clientType', 'PERSONAL');
      localStorage.setItem('clientId', clientId ? clientId.toUpperCase() : 'WAYABANK');
    }
    console.log({ search: new URLSearchParams(search).get('client') });
    if (new URLSearchParams(location.search).get("from") === "shared") {
      setSharedPost(true);
      setSharedPostId(new URLSearchParams(location.search).get("id"));
    }
    if (new URLSearchParams(location.search).get("corp_auth") === "true") {
      history.push("/corp-login");
    }
    if (new URLSearchParams(location.search).get("w") === "login") {
      history.push("/login");
    }
    if (new URLSearchParams(location.search).get("w") === "signup") {
      history.push("/signup");
    }
  }, []);

  const resendOtp = async (phoneNumber, businessId) => {
    setLoading(true);
    setPhone(phoneNumber);
    setBId(businessId);
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/auth/resend-otp/signup/${phoneNumber}${!isPersonal ? '?businessId='+businessId : ''}`,
      isPersonal ? 'PERSONAL' : 'CORPORATE'
    );
    if (res.status) {
      setLoading(false);
      swal("Done", res.message, "success").then(() => {
        setShowResendOtp(false);
        setShowVerifyAccount(true);
      });
    } else {
      setLoading(false);
      swal("Wrong", `${res.message}`, "error", {
        button: {
          text: "Resend OTP",
          className: "button-warning",
        },
      }).then(() => {
        setPhone("");
      });
    }
  };

  const completeSignup = async () => {
    setLoading(true);
    const postData = {
      otp: Number(otp),
      phoneOrEmail: phone,
      businessId: !isPersonal ? bId : '',
    };
    const res = await httpPost(
      "/api/v1/auth/verify-otp",
      postData,
      customBaseUrl.authUrl,
      isPersonal ? 'PERSONAL' : 'CORPORATE',
      true,
      !isPersonal ? phone : ''
    );
    if (res.status) {
      setLoading(false);
      setOtp("");
      setPhone("");
      setShowVerifyAccount(false);
      setShowVerifyOtp(false);
      swal("Done", res.message, "success").then(() => {
        history.push(`/login?client=${client}`);
      });
    } else {
      setLoading(false);
      setOtp("");
      swal("Oops!", res.message, "error");
    }
  };

  const getOtp = async () => {
    //  return handleSubmit();
    var phoneValidation= isPersonal ? /^([\s\(\)\-]*\d[\s\(\)\-]*){13}$/ : /^([\s\(\)\-]*\d[\s\(\)\-]*)/; 
    var mailValidation= /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
    if(!validateInput(data.emailOrPhoneNumber)) return;
    if (data.emailOrPhoneNumber.match(phoneValidation)) {
      // correct phone structure
    } else if (data.emailOrPhoneNumber.match(mailValidation)) {
      // correct mail format
    } else {
      // incorrect structure
      swal("Oops!", isPersonal ? "Login phone number or registered email" : "Login Business id" , "error");
      return;
    }
    showLoader();

    const res = await httpPost(`/api/v1/auth/generate-login-otp/${
      !isPersonal ? '?businessId=' + data?.emailOrPhoneNumber : '?email=' + data?.emailOrPhoneNumber
    }`,
    {},
    customBaseUrl.authUrl,
    isPersonal ? 'PERSONAL' : 'CORPORATE',
    true
    );
    if (res.status) {
      setShowVerifyOtp(true);
      hideLoader();      
    } else {
      hideLoader();
      setLoading(false);
      if(res?.message == " 2FA not active for this user"){
        handleSubmit();
      }else{
        swal("Error", res?.message || 'Validation Error!', "error");
      }
    }
  }
  const handleResendOTP = async () => {
    setLoading(true);
    validateInput(resetEmail);
    const bid = localStorage.getItem('businessId')
    let res;
    if (checkAcess(resetEmail)) {
      res = await httpGet(
        `${customBaseUrl.authUrl}/api/v1/password/forgot-password/byPhone${
          isPersonal ? '?phoneNumber=' + resetEmail : '?phoneNumber=' + resetEmail + '&&businessId=' + bid
        }`,
        isPersonal ? 'PERSONAL' : 'CORPORATE',
        '',
        true
      );
    } else {
      if (validator.isEmpty(resetEmail)) {
        swal('Oops!', 'Email cannot be empty', 'error');
        setLoading(false);
        return;
      }
      const url = undefined;
      showLoader();
      res = await httpGet(
        `${customBaseUrl.authUrl}/api/v1/password/forgot-password/byEmail${
          isPersonal ? '?email=' + resetEmail : '?email=' + resetEmail + '&&businessId=' + bid
        }`,
        isPersonal ? 'PERSONAL' : 'CORPORATE',
        '',
        true
      );
    }

    if (res.status) {
      setLoading(false);
      swal('Done', res.message, 'success')
      hideLoader();
    } else {
      setLoading(false);
      swal('Oops!', res.message, 'error');
      hideLoader();
    }
  };
  const handleSubmit = async () => {
    setLoading(true);
    if (validator.isEmpty(data.emailOrPhoneNumber)) {
      setLoading(false);
      swal("Oops!", "Email cannot be empty", "error");
      return;
    }
    if (validator.isEmpty(data.password)) {
      setLoading(false);
      swal("Oops!", "Password cannot be empty", "error");
      return;
    }
    try {
      const res = await httpPost(
        "/api/v1/auth/login",
        { ...data, otp, admin: false },
        customBaseUrl.authUrl,
        isPersonal ? 'PERSONAL' : 'CORPORATE',
        true
      );
      // console.log({ res });
      if (res?.status) {
        const token = res?.data?.token;
        setUserData(res?.data);
        localStorage.setItem('token', token);
        //  setShowAccountOption(true);
        setLoading(false);
        localStorage.setItem('isPinCreated', res.data?.pinCreated);
        // setPasswordAge(res.data.passwordAge);
        /* if (passwordAge > 90){
          swal("Wrong", "Please reset password", "error");
          setShowPasswordreset(true);
          return;
        } */
        /* if (!res.data.pinCreated) {
          localStorage.setItem("firstLogin", true);
          // setShowPin(true);
        } */
        if(isPersonal && res?.data?.corporate) return swal('Oops!', "You don't have a Personal Account", 'error');
        if(!isPersonal && !res?.data?.corporate) return swal('Oops!', "You don't have a Business Account", 'error');
        httpPost(
          '/api/v1/history/save',
          {
            city: location?.userLocation?.city,
            country: location?.userLocation?.country_name,
            device: location?.device,
            id: 0,
            ip: location?.userLocation?.IPv4,
            province: location?.userLocation?.state,
            userId: res.data.user.id,
          },
          customBaseUrl.authUrl,
          isPersonal ? 'PERSONAL' : 'CORPORATE'
        );
        if (sharedPost) {
          history.push(`/post/${sharedPostId}`);
        } else {
          // history.push('/products');
          console.log("decodedTokenhiiiii");
          const token = res?.data?.token;
          const decodedToken = decodeToken(token);
          const expDate = new Date(decodedToken.exp * 1000);
          const newDate = new Date();
          localStorage.setItem('token', token);
          localStorage.setItem('userId', res?.data?.userId);
          console.log('userId', res?.data?.userId);
          // if (expDate > newDate) {
            const encoded = encrypt(res?.data?.token);
            const tok = res?.data?.token?.replace('serial ', '');
            if (res.data.corporate) {
            window.location.href = 
              client === 'wayaquick'
                ? `${envConfig.wayaquickAppUrl}/auth?client=web&iv=${encoded.iv}&token=${encoded.encryptedData}&userId=${res?.data?.user.id}&pinCreated=${res.data?.pinCreated}&profileId=${res?.data?.profileSubscription?.profileId}`
                : client === 'wayapos'
                ? `${envConfig.wayaposAppUrl}/auth?client=web&iv=${encoded.iv}&token=${encoded.encryptedData}&userId=${res?.data?.user.id}&pinCreated=${res.data?.pinCreated}&profileId=${res?.data?.profileSubscription?.profileId}&t=${tok}`
                : client === 'wayagram'
                ? `${envConfig.wayagramAppUrl}/auth?client=web&iv=${encoded.iv}&token=${encoded.encryptedData}&userId=${res?.data?.user.id}&pinCreated=${res.data?.pinCreated}&profileId=${res?.data?.profileSubscription?.profileId}&t=${tok}&type=CORPORATE`
                : `${envConfig.corporateAppUrl}/login?client=web&iv=${encoded.iv}&token=${encoded.encryptedData}&userId=${res?.data?.user.id}&pinCreated=${res.data?.pinCreated}&profileId=${res?.data?.profileSubscription?.profileId}`;
            // window.location.href = `http://localhost:3000/login?client=web&iv=${encoded.iv}&token=${encoded.encryptedData}&userId=${res?.data?.user.id}&pinCreated=${res.data?.pinCreated}&profileId=${res?.data?.profileSubscription?.profileId}`;
            // window.location.href = `http://localhost:3000/auth?client=web&iv=${encoded.iv}&token=${encoded.encryptedData}&userId=${res?.data?.user.id}&pinCreated=${res.data?.pinCreated}&profileId=${res?.data?.profileSubscription?.profileId}&t=${tok}&type=CORPORATE`;
            // window.location.href = `https://ubiquitous-space-garbanzo-9r7959gr5pq2x46x-3000.app.github.dev/login?client=web&iv=${encoded.iv}&token=${encoded.encryptedData}&userId=${res?.data?.user.id}&pinCreated=${res.data?.pinCreated}&profileId=${res?.data?.profileSubscription?.profileId}`;
          } else {
                 window.location.href = 
                 client === 'wayagram'
              ? `${envConfig.wayagramAppUrl}/auth?client=web&iv=${encoded.iv}&token=${encoded.encryptedData}&userId=${res?.data?.user.id}&pinCreated=${res.data?.pinCreated}&profileId=${res?.data?.profileSubscription?.profileId}&t=${tok}&type=PERSONAL`
              : window.location.href = `${envConfig.personalAppUrl}/login?client=web&iv=${encoded.iv}&token=${encoded.encryptedData}&userId=${res?.data?.user.id}&pinCreated=${res.data?.pinCreated}&profileId=${res?.data?.profileSubscription?.profileId}`;
            // window.location.href = `http://localhost:3000/login?client=web&iv=${encoded.iv}&token=${encoded.encryptedData}&userId=${res?.data?.user.id}&pinCreated=${res.data?.pinCreated}&profileId=${res?.data?.profileSubscription?.profileId}`;
            //  window.location.href = `https://verbose-capybara-rq4g6g5qvwgf5j7g-3000.app.github.dev/login?client=web&iv=${encoded.iv}&token=${encoded.encryptedData}&userId=${res?.data?.user.id}&pinCreated=${res.data?.pinCreated}&profileId=${res?.data?.profileSubscription?.profileId}`;
            //  window.location.href = `http://localhost:3000/auth?client=web&iv=${encoded.iv}&token=${encoded.encryptedData}&userId=${res?.data?.user.id}&pinCreated=${res.data?.pinCreated}&profileId=${res?.data?.profileSubscription?.profileId}&t=${tok}&type=PERSONAL`;

            }
          // } else {
            // setShowPasswordreset(true);
            setLoading(false);
          // }
        }
      } else {
        setLoading(false);
        console.log({ res1: res.includes('validate your BVN') });
        swal(
          "Oops!",
          res?.message === "User account is disabled, kindly contact Waya Admin"
            ? "Your account has not been verified, proceed to verify"
            : res.includes("validate your BVN")
            ? "Your BVN has not been verified, proceed to verify"
            : res?.message,
          "error"
        ).then(() => {
          if (
            res?.message === "User account is disabled, kindly contact Waya Admin"
          ) {
            setEmail(data.emailOrPhoneNumber);
            setShowVerifyOtp(true);
            setPhone(data.emailOrPhoneNumber);
          }
          if (
            res?.message === "User is disabled" ||
            res?.message?.includes("Account not Verified")
          ) {
            setEmail(data.emailOrPhoneNumber);
            setPhone(data.emailOrPhoneNumber);
            setOtp("");
            setShowResendOtp(true);
            // setShowVerifyAccount(true);
          }
          if (res.includes("validate your BVN")) {
            const firstObjectEndIndex = res.indexOf('}');

              let userId = null;

              if (firstObjectEndIndex !== -1) {
                const firstObjectString = res.substring(0, firstObjectEndIndex + 1);

                try {
                  const firstObject = JSON.parse(firstObjectString);
                  userId = firstObject.userId;
                } catch (error) {
                  console.error("Error parsing the first part of the response:", error);
                }
              } else {
                console.warn("Could not find the end of the first JSON object.");
              }
              setUserId(userId);
              setShowValidateBVN(true);
          }
        });
      }
    } catch (error) {
      console.log({ error });
      setLoading(false);
      swal("Oops!", error, "error");
    } finally {
      setLoading(false);
    }
  };

  const validateInput = (val) => {
    setPwarnin('');
    const phoneValidation = isPersonal ? /^([\s()-]*\d[\s()-]*){13}$/ : /^([\s()-]*\d[\s()-]*){12}/;
    const mailValidation = isPersonal ?
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ : /^([\s()-]*\d[\s()-]*){12}/;

    if (val.match(phoneValidation)) {
      // correct phone structure
      setPwarnin('');
      return true;
    }
    if (val.match(mailValidation)) {
      // correct mail format
      setPwarnin('');
      return true;
    }
    if (val?.length > 0)
      setPwarnin(
        isPersonal ? 'Please enter a valid email address or phone number' : 'Please enter a valid Business Id'
      );
    // incorrect structure
    return false;
  };

  const handleValidateBVN = async () => {
    setLoading(true);
    if (validator.isEmpty(data.bvn)) {
      setLoading(false);
      swal("Oops!", "BVN cannot be empty", "error");
      return;
    }
    if (data.bvn.length < 11) {
      setLoading(false);
      swal("Oops!", "BVN must be 11 digits", "error");
      return;
    }
    const bvnData = {
      userId,
      bvn: data.bvn,
    }
    const res = await httpPost(
      '/no-auth/validate-bvn',
      bvnData,
      customBaseUrl.kycUrl,
      isPersonal ? 'PERSONAL' : 'CORPORATE'
    );
    if (res.status) {
      setLoading(false);
      setShowValidateBVN(false);
      setData({ ...data, bvn: '' });
      swal("Done", res.message, "success").then(() => {
        history.push(`/login?client=${client}`);
      });
    } else {
      setLoading(false);
      setData({ ...data, bvn: '' });
      setShowValidateBVN(false);
      swal("Oops!", res.message, "error");
    }
  };

  useEffect(() => {
    getLocationInfo();
    return () => {
      setLocation({});
    };
  }, []);

  return (
    <div id="login-bg">
      <Modal
        isOpen={showValidateBVN}
        toggle={() => setShowValidateBVN(false)}
        centered
        size="md"
        className="modal-dialog-centered"
        style={{ maxWidth: "500px" }}
      >
        <div className="modal-body-rs col-sm-12 col-md-10">
        <div className="header-sec-modal" />
        <div className="waya-modal-body">
          <h1 className="modal-header-data mt-4">Validate your BVN</h1>
          {/* <p className="modal-header-sub-data text-secondary">
            Please input the BVN sent to your email address or phone number
          </p> */}
          <div className="inputbox-with-one-input-log">
            <input
              placeholder="BVN"
              type="text"
              value={data?.bvn}
              maxLength={11}
              onChange={(e) =>
                setData({ ...data, bvn: e.target.value.replace(/\s/g, "") })
              }
            />
          </div>
          <div className="submit-modal-btn-wrap-log">
            <Button
              type="button"
              loading={loading}
              onClick={() => handleValidateBVN()}
              content="Validate"
            />
          </div>
        </div>
        </div>
        </Modal>
      <div id="login-modal" className="my-auto modal-body-rs-log col-12">
        <div className="waya-modal-body-log">
          <div className="header-sec-modal-log" style={{ display: 'flex', flexDirection: 'column'}}>
            {!client ? (
            <Skeleton width='70%' height={60} />
            ) : (
            <img
              className="header-sec-logo-log mx-auto"
              style={{ width: '70%', height: 60 }}
              src={client === 'wayabank' 
                ? Logo
                : client === 'wayaquick' 
                ? quickLogo
                : client === 'wayapos' 
                ? posLogo
                : client === 'wayagram' 
                ? gramLogo
                : '' }
              alt=""
            />
            )}
            <h6 className="navbar-brand ml-4 pl-3 text-black nav-link fst-italic">
              Wire your money, your way
            </h6>
          </div>
          {client === 'wayabank' || client === 'wayagram' ? (
            <>
            <h1 className="modal-header-data-log">Login as a</h1>
            
            <div className="row m-0 mb-5">
              <div className="col reg-active btn" 
              onClick={() => {
                localStorage.setItem('clientType', 'PERSONAL');
                setIsPersonal(true)
              }}
              style={{borderRadius: 5, textAlign: 'center', background: isPersonal ? '#FF6700': ''}}>
                Personal
              </div>
              <div className="col reg-active text-centre btn"
                onClick={() => {
                  localStorage.setItem('clientType', 'CORPORATE');
                  setIsPersonal(false);
                }}
                style={{borderRadius: 5, textAlign: 'center', background: !isPersonal ? '#FF6700': ''}}>
                Corporate
              </div>
            </div>
                </>
          ) : (
            ''
          )}
          <form>
            <div className="inputbox-with-one-input-log">
              <input
                placeholder={isPersonal ? `Email or Phone (2348020000000)` : `Business ID`}
                type="text"
                value={data?.emailOrPhoneNumber}
                onPointerOut={(e) => validateInput(e.target.value)}
                onChange={(e) =>
                  setData({ ...data, emailOrPhoneNumber: e.target.value.replace(/\s/g, "") })
                }
              />
              <span className="text-danger">{pWarning}</span>
            </div>

            <div className="inputbox-with-one-input-log toggle-pass-wrapper">
              <ToggleInput
                placeholder="Password"
                type="password"
                onChange={(e) => {
                  setData({ ...data, password: e.target.value });
                }}
              />
            </div>
          </form>
          <div className="submit-modal-btn-wrap-log">
            <Button
              type="button"
              loading={loading}
              onClick={getOtp}
              content="Login"
            />
          </div>

          <div className="text-center">
            <span className="">
              Dont have an account?{" "}
              <Link className="text-primary" to={`signup?client=${client}`}>
                Sign up instead
              </Link>
            </span>
            <br />
            <span
              className="text-primary"
              onClick={() => setShowForgotPasswordModal(true)}
              style={{ cursor: 'pointer'}}
            >
              Forgot your password?
            </span>
          </div>
          {/* <div
            style={{
              fontSize: "14px",
              width: "80%",
            }}
            className="mx-auto d-flex  login-footer justify-content-between text-black"
          >
            <span>© Wayapay</span>
            <span>About</span>
            <span>Insights</span>
            <span>Terms</span>
            <span>Privacy</span>
          </div> */}
        </div>
      </div>
      {showForgotPasswordModal ? (
        <ForgotPassword
          center="true"
          showModal={showForgotPasswordModal}
          hideModal={setShowForgotPasswordModal}
          setShowResetPassword={setShowResetPassword}
          reset={checkReset}
          clientType={isPersonal ? 'PERSONAL' : 'CORPORATE'}
          isPersonal={isPersonal}
          setIsPersonal={setIsPersonal}
          resetEmail={resetEmail}
          setResetEmail={setResetEmail}
          setResetBID={setResetBID}
        />
      ) : (
        ""
      )}
      {showVerifyOtp ? (
        <VerifyOtp
          center
          showModal={showVerifyOtp}
          hideModal={setShowVerifyOtp}
          otp={otp}
          setOtp={setOtp}
          separator=""
          title="Verify Your Account"
          description="Please input the OTP sent to your email address or phone number"
          //isResendOtp
          buttonLabel="Verify"
          handleSubmit={handleSubmit}
          loading={loading}
          numInputs={6}
          clientType={isPersonal ? 'PERSONAL' : 'CORPORATE'}
          //resendOtp={setShowResendOtp}
        />
      ) : (
        ""
      )}
      {showVerifyAccount ? (
        <VerifyOtp
          center
          showModal={showVerifyAccount}
          hideModal={setShowVerifyAccount}
          otp={otp}
          setOtp={setOtp}
          separator=""
          title="Verify Your Account"
          description="Please input the OTP sent to your email address or phone number"
          //isResendOtp
          buttonLabel="Verify"
          handleSubmit={completeSignup}
          loading={loading}
          numInputs={6}
          //resendOtp={setShowResendOtp}
        />
      ) : (
        ""
      )}
      {showResetPassword ? (
        <ResetPassword
          center="true"
          showModal={showResetPassword}
          hideModal={setShowResetPassword}
          clientType={isPersonal ? 'PERSONAL' : 'CORPORATE'}
          handleResendOTP={handleResendOTP}
        />
      ) : showPasswordReset ? (
        <ResetPasswordModal
          center="true"
          showModal={showPasswordReset}
          hideModal={setShowPasswordreset}
          setForgotPassord={setShowForgotPasswordModal}
          reset={setCheckReset}
          clientType={isPersonal ? 'PERSONAL' : 'CORPORATE'}
        />
      ) : (
        ""
      )}
      {showResendOtp ? (
        <ResendOtp
          center="true"
          showModal={showResendOtp}
          hideModal={setShowResendOtp}
          title="Resend OTP for Verification"
          description="Please input the email or mobile number"
          handleSubmit={resendOtp}
          loading={loading}
          type={isPersonal ? 'personal' : 'corporate'}
        />
      ) : (
        ""
      )}
      {
        showAccountOption ? (
          <SelectAccount 
            showModal={showAccountOption}
            hideModal={setShowAccountOption}
            userData={userData}
            isPersonal={isPersonal}
            />
        ) : (
          null
        )
      }
    </div>
  );
};

export default LoginPage;
