import React, { useState } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import validator from 'validator';
import { Modal } from 'reactstrap';
import './index.scss';
import { customBaseUrl, httpGet } from '../../services/http';
import Button from '../Button';
import { checkAcess } from '../../utils/helper';
// import Logo from '../../assets/images/wayaBankLogo1.png';
import Logo from '../../assets/images/newLogo.svg';


const ForgotPassword = (props) => {
  const {
    showModal,
    hideModal,
    center,
    setShowResetPassword,
    setShowLoginModal,
    setShowSignupModal,
    setResetEmail,
    isPersonal,
    setIsPersonal,
    reset,
  } = props;
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [pWarning, setPwarnin] = useState('');
  const [pWarning2, setPwarnin2] = useState('');
  const [businessId, setBusinessId] = useState('');
  //  const [isPersonal, setIsPersonal] = useState(true)


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    validateInput(email);
    setResetEmail(email);
    let res;
    if (checkAcess(email)) {
      res = await httpGet(
        `${customBaseUrl.authUrl}/api/v1/password/forgot-password/byPhone${
          isPersonal ? '?phoneNumber=' + email : '?phoneNumber=' + email + '&&businessId=' + businessId
        }`,
        isPersonal ? 'PERSONAL' : 'CORPORATE',
        '',
        true
      );
    } else {
      if (validator.isEmpty(email)) {
        swal('Oops!', 'Email cannot be empty', 'error');
        setLoading(false);
        return;
      }
      const url = undefined;
      res = await httpGet(
        `${customBaseUrl.authUrl}/api/v1/password/forgot-password/byEmail${
          isPersonal ? '?email=' + email : '?email=' + email + '&&businessId=' + businessId
        }`,
        isPersonal ? 'PERSONAL' : 'CORPORATE',
        '',
        true
      );
    }

    if (res.status) {
      setLoading(false);
      swal('Done', res.message, 'success').then(() => {
        localStorage.setItem('email', email);
        localStorage.setItem('businessId', businessId);
        setShowResetPassword(true);
        hideModal(false);
      });
    } else {
      setLoading(false);
      swal('Oops!', res.message, 'error');
    }
  };
  const validateInput = (val, t) => {
    const phoneValidation = /^([\s()-]*\d[\s()-]*){13}$/;
    const businessId = /^([\s()-]*\d[\s()-]*){12}$/;
    const mailValidation =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (val.match(phoneValidation)) {
      // correct phone structure
      setPwarnin('');
      return true;
    }
    if (val.match(mailValidation)) {
      // correct mail format
      setPwarnin('');
      return true;
    }
    if (val.match(businessId)) {
      // correct mail format
      setPwarnin2('');
      return true;
    }
    t === 1 ? (
      setPwarnin('Please enter a valid email address or valid phone number (2348020000000')
    ) : (
      setPwarnin2('Please enter a valid Business ID')
    )
    // incorrect structure
    return false;
  };
  return (
    <div id='forgot-password-modal'>
      <Modal
        isOpen={showModal}
        toggle={() => hideModal(false)}
        centered
        size='md'
      >
        <div
          id='forgot-password-modal'
          className='modal-body-rs col-sm-12 col-md-10'
          style={{ marginTop: '30px' }}
        >
          <div className='header-sec-modal'>
            <input
              type='image'
              onClick={() => hideModal(false)}
              className='header-img1'
              src='./assets/image/back.png'
              alt=''
              style={{ opacity: 0 }}
            />
            {/* <img
              className='header-sec-logo'
              src={Logo}
              alt=''
              style={{ height: '35px' }}
            /> */}
            <input
              type='image'
              onClick={() => hideModal(false)}
              className='header-img1'
              src='./assets/image/x.png'
              alt=''
              style={{ opacity: 0 }}
            />
          </div>

          <div className='waya-modal-body'>
            <h1 className='modal-header-data'>{`${reset?"Reset":'Forgot'} Password?`}</h1>

            {/* <span className='text-secondary'>
              {`
              Please enter your ${clientType === 'PERSONAL' ? 'email or phone number' : 'business Id'} to reset your password
              `}
            </span> */}            
            <div className='inputbox-with-one-input'>
              <label>Select Account Type</label>
              <select
                onChange={(e) => {
                  console.log(e.target.value);
                  localStorage.setItem('clientType', e.target.value === 'true' ? 'PERSONAL' : 'CORPORATE');
                  setIsPersonal(e.target.value === 'true' ? true : false);
                }}
              >
                <option value>Personal</option>
                <option value={false}>Corporate</option>
              </select>
            </div>

            <div className='inputbox-with-one-input'>
              <input
                placeholder={'Email or Phone' }
                type='text'
                value={email}
                onPointerOut={(e) => validateInput(e.target.value, 1)}
                onChange={(e) => setEmail(e.target.value)}
              />
              <span className="text-danger">{pWarning}</span>
            </div>
            {!isPersonal ? (
               <div className='inputbox-with-one-input'>
               <input
                 placeholder='Busness Id'
                 type='text'
                 value={businessId}
                 onPointerOut={(e) => validateInput(e.target.value, 2)}
                 onChange={(e) => setBusinessId(e.target.value)}
               />
               <span className="text-danger">{pWarning2}</span>
             </div>
            ) : (
              ''
            )}
            <div className='submit-modal-btn-wrap'>
              <Button
                type='button'
                loading={loading}
                onClick={handleSubmit}
                content='Submit'
              />
            </div>

            <span
              className=' text-secondary-dark'
              role='button'
              tabIndex={0}
              aria-hidden='true'
              onClick={() => {
                hideModal(false);
                //  setShowLoginModal(true);
              }}
            >
              Back to Sign In?
            </span>

            <div className='back text-center'>
              <span className='text-secondary'>
                Dont have an account?{' '}
                <a
                  className='text-secondary-dark'
                  href='/#'
                  onClick={(e) => {
                    e.preventDefault();
                    hideModal(false);
                    //  setShowSignupModal(true);
                  }}
                >
                  Sign up instead{' '}
                </a>
              </span>
            </div>

            {/* <div className='modal-footer p-0'>
              <div
                className='d-flex text-center w-100'
                style={{ justifyContent: 'space-between' }}
              >
                <div>Wayapay</div>
                <div>About</div>
                <div>Insights</div>
                <div>Terms</div>
                <div>Privacy</div>
              </div>
            </div> */}
          </div>
        </div>
      </Modal>
    </div>
  );
};

ForgotPassword.defaultProps = {
  setShowLoginModal: false,
  setShowSignupModal: false,
};

ForgotPassword.propTypes = {
  hideModal: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  center: PropTypes.bool.isRequired,
  setShowResetPassword: PropTypes.bool.isRequired,
  setShowLoginModal: PropTypes.bool,
  setShowSignupModal: PropTypes.bool,
};

export default ForgotPassword;
