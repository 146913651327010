import React, { useState } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import validator from 'validator';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import Button from '../Button';
import MerchantForm3 from './MerchantForm3';
import { Modal } from 'reactstrap';

export default function MerchantForm(props) {
  const { setPage, setData, data, businessTypes, handleSignup, resendOtp, showAddEmail, setShowAddEmail} = props;
  const [loading, setLoading] = useState(false);
  // const businessTypeOptions = [
  //   'Catering and Food',
  //   'Cakes and Pastries',
  //   'Event Planning',
  //   'Music and DJ',
  //   'Event Courier and Logistics',
  //   'Logistics',
  //   'Health and Skin Care',
  //   'Fashion',
  //   'Clothing  ,Accessories, and Shoes',
  //   'Makeup',
  //   'Hairs',
  //   'Computer, Accessories, and Services',
  //   'Babies and Kids',
  //   'Art, Crafts, and Collectibles',
  //   'Home and Gardens',
  //   'Groceries',
  //   'Transportation',
  //   'Pharmacy/ Hospital',
  //   'Aggregator',
  //   'Others',
  // ];

  return (
    <form>
      <Modal isOpen={showAddEmail} toggle={() => setShowAddEmail(false)} centered>
        <div className='modal-body-rs p-2 pb-3'>
          <div className='waya-modal-body'>
            <div className='inputbox-with-one-input-log mt-5'>
              <div className='mb-5'>
                Add Email Address
              </div>
              <input
                placeholder='Email Address'
                type='email'
                value={data?.myEmail}
                onChange={(e) => {
                  setData({ ...data, myEmail: e.target.value });
                }}
              />
            </div>
            <div className='submit-modal-btn-wrap-log'>
              <Button
                type='button'
                loading={loading}
                disabled={loading && false}
                onClick={() => resendOtp(data?.myEmail)}
                content='Proceed'
              />
            </div>
          </div>
        </div>
      </Modal>
      <div className='inputbox-with-one-input-log'>
        <input
          placeholder='First name'
          type='text'
          disabled
          value={data.firstName}
          onChange={(e) => {
            setData({ ...data, firstName: e.target.value });
          }}
        />
      </div>

      <div className='inputbox-with-one-input-log'>
        <input
          placeholder='Surname'
          value={data.surname}
          disabled
          type='text'
          onChange={(e) => {
            setData({ ...data, surname: e.target.value });
          }}
        />
      </div>

      <div className='inputbox-with-one-input-log'>
        <input
          placeholder='Personal Email Address'
          type='text'
          disabled
          value={data.email}
          onChange={(e) =>
            setData({ ...data, email: e.target.value })
          }
        />
        {!data?.email &&<div className="input-group-prepend">
          <span className="ml-3 mt-2" id="basic-addon1 text-danger"
          style={{ fontSize: 12, cursor: 'pointer', color: 'red'}}
          onClick={()=> setShowAddEmail(true)}
          >
            Add Email
          </span>
        </div>}
      </div>
      <div className='inputbox-with-one-input-log'>
        <input
          placeholder='Business Email Address'
          type='text'
          value={data.orgEmail}
          onChange={(e) =>
            setData({ ...data, orgEmail: e.target.value })
          }
        />
      </div>
      <div className='inputbox-with-one-input-log'>
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text" id="basic-addon1">Tel</span>
          </div>
        <input
          value={data?.phoneNumber}
          className="form-control"
          disabled
          length='13'
          maxLength={13}
          onChange={(e)=>{                      
            const onlyDigits = e.target.value.replace(/\D/g, "");
            setData({ ...data, phoneNumber: onlyDigits })
          }}  />
      </div>
      </div>

      <div className='inputbox-with-one-input-log'>
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text" id="basic-addon1">Business Tel</span>
          </div>
        <input
          value={data?.orgPhone}
          className="form-control"
          length='13'
          maxLength={13}
          onChange={(e)=>{                      
            const onlyDigits = e.target.value.replace(/\D/g, "");
            setData({ ...data, orgPhone: onlyDigits })
          }}  />
      </div>
      </div>
      <div className='inputbox-with-one-input'>
        <select
          value={data.businessType ? data.businessType : ""}
          onChange={(e) => {
            setData({ ...data, businessType: e.target.value });
          }}
        >
          <option value='' data-default hidden>
            Business Type
          </option>
          {businessTypes?.length
            ? businessTypes?.map((item) => (
              <option value={item?.businessType} key={item}>
                {item?.businessType}
              </option>
            ))
            : ''}
        </select>
      </div>
      <div className='inputbox-with-one-input'>
        <input
          placeholder='Business Name'
          type='text'
          value = {data?.orgName}
          onChange={(e) => {
            setData({ ...data, orgName: e.target.value });
          }}
        />
      </div>

      <div className='inputbox-with-one-input'>
        <input
          placeholder='Office Address'
          type='text'
          value = {data?.officeAddress}
          onChange={(e) => {
            setData({ ...data, officeAddress: e.target.value });
          }}
        />
      </div>
      <MerchantForm3
        setData={setData}
        data={data}
        setLoading={setLoading}
        loading={loading}
        setPage={setPage}
        handleSignup={handleSignup}      
      />
      {/* <div className='inputbox-with-one-input'>
        <input
          placeholder='Business Email Address'
          type='text'
          value = {data?.orgEmail}
          onChange={(e) => {
            setData({ ...data, orgEmail: e.target.value });
          }}
        />
      </div> */}

      {/* <div className="form-group">
        <input type="checkbox" id="term" />{' '}
        <span className="add-cursor">
          By signing up, you agree with the{' '}
          <a href="/#" className="primary-link">
            terms and conditions
          </a>
        </span>
      </div> */}

      {/* <div className='submit-modal-btn-wrap'>
        <Button
          type='button'
          loading={loading}
          content='Next'
          onClick={() => {
            setLoading(true);
            // if (validator.isEmpty(data.orgName)) {
            //   swal('Oops!', 'Organization name cannot be empty', 'error');
            //   setLoading(false);
            //   return;
            // }
            // if (validator.isEmpty(data.businessType)) {
            //   swal('Oops!', 'Business type cannot be empty', 'error');
            //   setLoading(false);
            //   return;
            // }
            // if (validator.isEmpty(data.orgEmail)) {
            //   swal('Oops!', 'Organization email cannot be empty', 'error');
            //   setLoading(false);
            //   return;
            // }
            // if (validator.isEmpty(data.orgPhone)) {
            //   swal('Oops!', 'Organization phone cannot be empty', 'error');
            //   setLoading(false);
            //   return;
            // }
            // if (!data.orgName) {
            //   swal('Oops!', 'Organization name cannot be empty', 'error');
            //   setLoading(false);
            //   return;
            // }
            if (!data.businessType) {
              swal('Oops!', 'Business type cannot be empty', 'error');
              setLoading(false);
              return;
            }
            if (!data.orgEmail) {
              swal('Oops!', 'Organization email cannot be empty', 'error');
              setLoading(false);
              return;
            }
            if (!validator.isEmail(data.orgEmail)) {
              swal('Oops!', 'Invalid Email Format', 'error');
              setLoading(false);
              return;
            }
            if (!data.orgPhone) {
              swal('Oops!', 'Organization phone cannot be empty', 'error');
              setLoading(false);
              return;
            }

            setPage(2);
          }
          }
        />

      </div> */}
    </form>
  );
}

// MerchantForm.propTypes = {
//   setPage: PropTypes.string.isRequired,
//   setData: PropTypes.func.isRequired,
//   data: PropTypes.shape.isRequired,
// };
